import React from 'react';
import { Typography } from '@mui/material';

import Course from '../../models/Course.model';
import { CourseCardConstants } from '../../constants';
import CourseCardSkeleton from './CourseCardSkeleton.component';
import MenuIcons from './CourseCard.menu';
import Styles from './CourseCardPending.style';

interface Props {
  course: Course;
}

const CourseCardPending = ({ course }: Props) => {
  return (
    <CourseCardSkeleton
      course={course}
      bannerHandler={() => {}}
      statusText="PENDING"
      contentSlot={
        <Typography
          sx={Styles.contentText}
          dangerouslySetInnerHTML={{
            __html: CourseCardConstants.pending.contentText,
          }}
        />
      }
      actionSlot={
        <Typography
          sx={Styles.actionText}
          dangerouslySetInnerHTML={{
            __html: CourseCardConstants.pending.actionText,
          }}
        />
      }
      menuIcons={
        <MenuIcons
          hasProctorUrl={course.isActive}
          checkList={{ display: true }}
          pdf={{ display: !!course.syllabusUri }}
          calendar={{ display: course.isProctorable }}
        />
      }
    />
  );
};

export default CourseCardPending;
