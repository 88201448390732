import { spacing } from '../../../themes/theme';
import { themeColors } from '../../../themes/colors';
export default {
  root: {
    mt: `${spacing * 10}px`,
    mb: `${spacing * 10}px`,
    position: 'relative',
    width: `95%`,
  },
  container: {
    p: `${spacing * 2}px`,
    borderRadius: `${spacing * 1}px`,
    bgcolor: themeColors.midGrey,
  },
  textHeader: {
    fontSize: `${spacing * 3.5}px`,
    fontWeight: 700,
  },
  boxInfo: {
    mt: `${spacing * 1}px`,
    '& .MuiTypography-root': {
      fontSize: `${spacing * 1.75}px`,
    },
    '& a': {
      textDecoration: 'none',
      color: themeColors.darkBlue,
    },
  },
  avatar: {
    position: 'absolute',
    height: 140,
    width: 140,
    right: `${8 * 5}px`,
    bottom: `${8 * 3}px`,
  },
  textContactInfo: {
    '&.MuiTypography-root': {
      fontSize: `${spacing * 2}px`,
    },
  },
  textTimings: {
    '&.MuiTypography-root': {
      fontSize: `${spacing * 2}px`,
    },
  },
};
