import React, { useEffect } from 'react';
import Styles from './SupportRequests.styles';
import { supportRequests } from '../../../constants';
import { Divider } from '@mui/material';

const SupportRequests = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.href]);
  return (
    <div style={Styles.container}>
      <h1 style={Styles.textHeader}>{supportRequests.header}</h1>
      <h6
        dangerouslySetInnerHTML={{ __html: supportRequests.subHeader }}
        style={Styles.textSubHeader}
      />
      <Divider sx={Styles.divider} />
    </div>
  );
};

export default SupportRequests;
