import React from 'react';
import { Button } from '@mui/material';

import Course from '../../models/Course.model';
import Styles from './CourseCardFreeTrial.style';
import { CourseButton, dataTestIds, FreeTrialCourses } from '../../constants';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import CourseCardSkeleton from './CourseCardSkeleton.component';
import {
  getAssignmentList,
  getLMSProviderDetails,
} from '../../module/dashboard/redux/dashboardAsync.actions';
import MenuIcons from './CourseCard.menu';
import { VariantButton } from '../../themes/properties';
import {
  acrobatiqProviderHandler,
  isAcrobatiqCourse,
} from '../../utils/acrobatiqLogin.util';
import CourseCardActionButtons from './CourseCardActionButtons.component';
import CourseCardProgress from './CourseCardProgress.component';

interface Props {
  course: Course;
}

const CourseCardFreeTrial = ({ course }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const handlePurchaseButtonClick = () => {
    window.open(
      FreeTrialCourses.find(
        (freeTrialCourse) => freeTrialCourse.id === course.courseIdentifier,
      )?.purchaseLink,
      '_self',
    );
  };

  const showCourseModal = () => {
    dispatch(getAssignmentList(course.courseIdentifier));
  };

  const courseProviderHandler = (
    providerCourseName: string,
    providerCourseId: string,
    cwpCode: string,
    courseIdentifier: string,
    resourceLinkId: string,
    resume: boolean,
  ) => {
    if (isAcrobatiqCourse(cwpCode)) {
      acrobatiqProviderHandler(
        providerCourseName,
        providerCourseId,
        resourceLinkId,
        resume,
      );
    } else {
      moodleProviderHandler(courseIdentifier, resourceLinkId, resume);
    }
  };

  const moodleProviderHandler = (
    courseIdentifier: string,
    linkId: string,
    resume: boolean,
  ) => {
    dispatch(
      getLMSProviderDetails({
        courseIdentifier: courseIdentifier,
        linkId: linkId,
        resume: resume,
      }),
    );
  };

  return (
    <CourseCardSkeleton
      course={course}
      bannerHandler={showCourseModal}
      statusText="FREE TRIAL COURSE"
      contentSlot={<CourseCardProgress course={course} />}
      actionSlot={
        <CourseCardActionButtons
          buttons={[
            <Button
              variant={VariantButton.outlined}
              sx={Styles.buttonPurchase}
              onClick={handlePurchaseButtonClick}
              key="action-purchase"
              data-testid={dataTestIds.courseCard.actionButton}
            >
              {CourseButton.purchaseCourse}
            </Button>,
            <Button
              sx={Styles.buttonPreview}
              onClick={() =>
                courseProviderHandler(
                  course?.providerCourseName ?? '',
                  course?.providerCourseId ?? '',
                  course?.cwpCode ?? '',
                  course?.courseIdentifier ?? '',
                  course?.resourceLinkId ?? '',
                  true,
                )
              }
              key="action-preview"
              data-testid={dataTestIds.courseCard.actionButton}
            >
              {CourseButton.previewCourse}
            </Button>,
          ]}
        />
      }
      menuIcons={
        <MenuIcons
          hasProctorUrl={course.isActive}
          checkList={{
            display: course.isProctorable,
            handler: showCourseModal,
          }}
        />
      }
    />
  );
};

export default CourseCardFreeTrial;
