import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Styles from './CourseModal.Styles';

import ProgressBar from './progressBar/ProgressBar.component';
import Topics from './topics/index.component';
import clockImage from '../../assets/images/clock.svg';
import { Button, Divider, Link, useMediaQuery } from '@mui/material';
import { VariantButton } from '../../themes/properties';
import {
  CouseModalConstants,
  Routes,
  cloudfrontImageBaseUrl,
  screenBreakpoints,
  CourseButton,
  FreeTrialCourses,
} from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import Course from '../../models/Course.model';
import Profile from '../../models/Profile.model';
import { closeModal } from '../dashboard/redux/dashboardSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faXmark,
  faShare,
  faPaperPlane,
  faBookmark,
} from '@fortawesome/pro-solid-svg-icons';
import {
  getLMSProviderDetails,
  proctoredExamUrl,
} from '../dashboard/redux/dashboardAsync.actions';
import { getTranscriptEligibleCourses } from '../../utils/utils';
import { useNavigate } from 'react-router';
import {
  isAcrobatiqCourse,
  acrobatiqProviderHandler,
} from '../../utils/acrobatiqLogin.util';

interface PropsType {
  isUserFreeTrial: boolean;
}

export default function CourseModal({ isUserFreeTrial }: PropsType) {
  const screenIPad = useMediaQuery(`(max-width:${screenBreakpoints.iPad})`);
  const screenPhone = useMediaQuery(`(max-width:${screenBreakpoints.phone})`);

  const courseProviderHandler = (
    providerCourseName: string,
    providerCourseId: string,
    cwpCode: string,
    courseIdentifier: string,
    resourceLinkId: string,
    resume: boolean,
  ) => {
    if (isAcrobatiqCourse(cwpCode)) {
      acrobatiqProviderHandler(
        providerCourseName,
        providerCourseId,
        resourceLinkId,
        resume,
      );
    } else {
      moodleProviderHandler(courseIdentifier, resourceLinkId, resume);
    }
  };

  const moodleProviderHandler = (
    courseIdentifier: string,
    linkId: string,
    resume: boolean,
  ) => {
    dispatch(
      getLMSProviderDetails({
        courseIdentifier: courseIdentifier,
        linkId: linkId,
        resume: resume,
      }),
    );
  };

  const show: boolean = useSelector(
    (state: RootState) => state.dashboard.courseDetailModal.showModal,
  );

  const course: Course | null = useSelector(
    (state: RootState) => state.dashboard.courseDetailModal.course,
  );

  const profile: Profile | null = useSelector(
    (state: RootState) => state.profile.profile,
  );

  const profileImageUrl = `${cloudfrontImageBaseUrl}/${profile?.avatar ?? ''}`;

  const courses: Array<Course> = useSelector(
    (state: RootState) => state.dashboard.courses,
  );

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const transcriptClickHandler = () => {
    navigate(Routes.transcript);
    dispatch(closeModal());
  };

  const handleScheduleProctoring = (course: Course | null) => {
    course && dispatch(proctoredExamUrl(course));
  };

  const scheduleProctoringView = (
    <Box sx={Styles.rootScheduleProctor}>
      <Box sx={Styles.containerScheduleProctor}>
        <Box sx={Styles.boxScheduleProctor}>
          {!screenIPad && (
            <img src={clockImage} style={Styles.imgScheduleProctor} />
          )}
          <Box sx={Styles.boxScheduleProctorText}>
            <Link
              sx={Styles.linkProctoring}
              onClick={() => handleScheduleProctoring(course)}
            >
              <Typography sx={Styles.textProctoring}>
                {CouseModalConstants.proctoring.title}
              </Typography>
            </Link>
            <Typography sx={Styles.textProctoringDesc}>
              {CouseModalConstants.proctoring.descriptionn}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const eligibleCourses = getTranscriptEligibleCourses(courses);
  const courseEligibilityExsists = eligibleCourses.find(
    (c) => c.id === course?.id,
  );

  const actionButtons = (
    <>
      {!course?.isPlagiarismViolation && !screenPhone && (
        <Button
          disabled={!courseEligibilityExsists}
          variant={VariantButton.contained}
          sx={Styles.button}
          startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
          onClick={transcriptClickHandler}
        >
          {CouseModalConstants.buttons.sendTranscript}
        </Button>
      )}
      <Button
        variant={VariantButton.contained}
        sx={Styles.button}
        disabled={!course?.isActive}
        onClick={() =>
          courseProviderHandler(
            course?.providerCourseName ?? '',
            course?.providerCourseId ?? '',
            course?.cwpCode ?? '',
            course?.courseIdentifier ?? '',
            course?.resourceLinkId ?? '',
            true,
          )
        }
        endIcon={<FontAwesomeIcon icon={faShare} />}
      >
        {CouseModalConstants.buttons.resumeCourse}
      </Button>
    </>
  );

  const purchaseClickHandler = () => {
    window.open(
      FreeTrialCourses.find(
        (freeTrialCourse) => freeTrialCourse.id === course?.courseIdentifier,
      )?.purchaseLink,
      '_self',
    );
  };

  const actionButtonsFreeTrial = (
    <>
      <Button
        variant={VariantButton.outlined}
        sx={{
          ...Styles.button,
          ...Styles.buttonPurchaseCourse,
        }}
        onClick={purchaseClickHandler}
      >
        {CourseButton.purchaseCourse}
      </Button>
      <Button
        variant={VariantButton.contained}
        sx={Styles.button}
        disabled={!course?.isActive}
        onClick={() =>
          courseProviderHandler(
            course?.providerCourseName ?? '',
            course?.providerCourseId ?? '',
            course?.cwpCode ?? '',
            course?.courseIdentifier ?? '',
            course?.resourceLinkId ?? '',
            true,
          )
        }
        endIcon={<FontAwesomeIcon icon={faShare} />}
      >
        {CourseButton.previewCourse}
      </Button>
    </>
  );

  const header = (
    <Box sx={Styles.boxHeader}>
      <FontAwesomeIcon icon={faBookmark} />
      <Box sx={Styles.boxCourseInfo}>
        <Typography sx={Styles.textCourseName}>
          {course?.name.toLowerCase()}
        </Typography>
        <Typography sx={Styles.textCourseCode}>
          {course?.code.toUpperCase()}
        </Typography>
      </Box>
      <Box sx={Styles.iconClose} onClick={handleClose}>
        <FontAwesomeIcon icon={faXmark} />
      </Box>
    </Box>
  );

  const subHeader = (
    <Box sx={Styles.boxSubHeader}>
      <Box sx={Styles.boxProgress}>
        <Box sx={Styles.boxProgressText}>
          <Typography sx={Styles.textCourse}>COURSE</Typography>
          <Typography sx={Styles.textProgress}>PROGRESS</Typography>
        </Box>
        <ProgressBar
          userProfileImg={profileImageUrl}
          progressValue={course?.progress ?? 0}
          showProfileImage={!screenIPad}
        />
      </Box>

      {course?.gradeValue && (
        <Box sx={Styles.boxCurrentGrade}>
          <Typography>{course?.grade}</Typography>
          <Box sx={Styles.boxCurrentGradeValue}>
            <Typography>{Math.round(course?.gradeValue * 10) / 10}%</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );

  return (
    <Modal open={show} onClose={handleClose} sx={Styles.modal}>
      <Box sx={Styles.root}>
        {header}

        {subHeader}

        {/* Topics */}
        <Topics course={course} />

        {!screenPhone &&
          !isUserFreeTrial &&
          course?.isProctorable &&
          scheduleProctoringView}

        <Divider />

        <Box sx={Styles.boxContainerBottomView}>
          {course?.isPlagiarismViolation && !screenPhone && (
            <Typography sx={Styles.textAPH}>
              {CouseModalConstants.ahpMessage}
            </Typography>
          )}

          {isUserFreeTrial ? actionButtonsFreeTrial : actionButtons}
        </Box>
      </Box>
    </Modal>
  );
}
